.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;

  body.fixed-header & {
    padding-top: $header-height;
  }

  body.aside-opened & {
    @media (min-width: 1600px) {
      margin-right: $aside-width;
    }
  }
}

.page-main {
  flex: 1 1 auto;
}

.page-content {
  margin: 0.75rem 0;

  @include media-breakpoint-up(md) {
    margin: 1.5rem 0;
  }
}

.page-header {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 1.5rem;
  flex-wrap: wrap;
}

.page-title {
  margin: 0;
  font-size: $h3-font-size;
  font-weight: 400;
  line-height: 2.5rem;
}

.page-title-icon {
  color: $text-muted;
  font-size: 1.25rem;
}

.page-subtitle {
  font-size: px2rem(13px);
  color: $text-muted-dark;
  margin-left: 2rem;

  a {
    color: inherit;
  }
}

.page-options {
  margin-left: auto;
}

.page-breadcrumb {
  flex-basis: 100%;
}

.page-description {
  margin: 0.25rem 0 0;
  color: $text-muted-dark;

  a {
    color: inherit;
  }
}

.page-single {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.content-heading {
  font-weight: 400;
  margin: 2rem 0 1.5rem;
  font-size: px2rem(20px);
  line-height: 1.25;

  &:first-child {
    margin-top: 0;
  }
}
