.ac-input {
  display: flex;
  padding: 4px;
  border: solid 1px #444;
}

.ac-field {
  display: none;
}

.ac-input input {
  border: none;
  flex: 1;
  margin-left: 4px;
}
