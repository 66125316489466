.table {
  thead {
    //background: $table-accent-bg;

    th {
      border-top: 0;
      border-bottom-width: 1px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  th {
    color: $text-muted;
    text-transform: uppercase;
    font-size: $h6-font-size;
    font-weight: 400;
  }
}

.table-md {
  th,
  td {
    padding: 0.5rem;
  }
}

.table-vcenter {
  td,
  th {
    vertical-align: middle;
  }
}

.table-center {
  td,
  th {
    text-align: center;
  }
}

.table-striped {
  tbody tr:nth-of-type(odd) {
    background: transparent;
  }

  tbody tr:nth-of-type(even) {
    background-color: $table-accent-bg;
  }
}

.table-calendar {
  margin: 0 0 0.75rem;

  td,
  th {
    border: 0;
    text-align: center;
    padding: 0 !important;
    width: (100%/7);
    line-height: 2.5rem;
  }

  td {
    border-top: 0;
  }
}

.table-calendar-link {
  line-height: 2rem;
  min-width: calc(2rem + 2px);
  display: inline-block;
  border-radius: 3px;
  background: $gray-100;
  color: $body-color;
  font-weight: 600;
  transition: 0.3s background, 0.3s color;
  position: relative;

  &:before {
    content: "";
    width: 4px;
    height: 4px;
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    border-radius: 50px;
    background: $primary;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
    background: $primary;
    transition: 0.3s background;

    &:before {
      background: #fff;
    }
  }
}

.table-header {
  cursor: pointer;
  transition: 0.3s color;

  &:hover {
    color: $body-color !important;
  }

  &:after {
    content: "\f0dc";
    font-family: FontAwesome;
    display: inline-block;
    margin-left: 0.5rem;
    font-size: 0.75rem;
  }
}

.table-header-asc {
  color: $body-color !important;

  &:after {
    content: "\f0de";
  }
}

.table-header-desc {
  color: $body-color !important;

  &:after {
    content: "\f0dd";
  }
}
