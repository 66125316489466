.chips {
  margin: 0 0 -0.5rem;

  .chip {
    margin: 0 0.5rem 0.5rem 0;
  }
}

.chip {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  font-size: px2rem(14px);
  font-weight: 500;
  color: $text-muted-dark;
  padding: 0 0.75rem;
  border-radius: 1rem;
  background-color: $gray-100;
  transition: 0.3s background;

  .avatar {
    float: left;
    margin: 0 0.5rem 0 -0.75rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
  }

  //.close {
  //	cursor: pointer;
  //	float: right;
  //	font-size: 1.3rem;
  //	padding-left: .5rem;
  //	line-height: inherit;
  //	margin-left: .25rem;
  //}

  @at-root a#{&} {
    &:hover {
      color: inherit;
      text-decoration: none;
      background-color: $gray-200;
    }
  }
}
