.stamp {
  color: #fff;
  background: $gray-600;
  display: inline-block;
  min-width: 2rem;
  height: 2rem;
  padding: 0 0.25rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 3px;
  font-weight: 600;
}

.stamp-md {
  min-width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}
