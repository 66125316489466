.btn {
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-size: px2rem(13px);
  min-width: px2rem(38px);

  i {
    font-size: 1rem;
    vertical-align: -2px;
  }
}

.btn-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
}

.btn-secondary {
  @include button-variant(#fff, $input-border-color, #f6f6f6);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-pill {
  border-radius: 10rem;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.btn-square {
  border-radius: 0;
}

@each $vendor, $color in $social-colors {
  .btn-#{$vendor} {
    @include button-variant($color, $color);
  }
}

@each $vendor, $color in $colors {
  .btn-#{$vendor} {
    @include button-variant($color, $color);
  }
}

.btn-option {
  background: transparent;
  color: $text-muted;

  &:hover {
    color: $text-muted-dark;
  }

  &:focus {
    box-shadow: none;
    color: $text-muted-dark;
  }
}

.btn-sm {
  font-size: px2rem(12px);
  min-width: px2rem(26px);
}

.btn-lg {
  font-size: px2rem(16px);
  min-width: px2rem(44px);
  font-weight: 400;
}

.btn-list {
  margin-bottom: -0.5rem;
  font-size: 0;

  > .btn,
  > .dropdown {
    margin-bottom: 0.5rem;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.btn-loading {
  color: transparent !important;
  pointer-events: none;
  position: relative;

  &:after {
    content: "";
    animation: loader 500ms infinite linear;
    border: 2px solid #fff;
    border-radius: 50%;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    display: block;
    height: 1.4em;
    width: 1.4em;
    position: absolute;
    left: calc(50% - (1.4em / 2));
    top: calc(50% - (1.4em / 2));
    transform-origin: center;
    position: absolute !important;
  }

  &.btn-sm:after {
    height: 1em;
    width: 1em;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
  }

  &.btn-secondary:after {
    border-color: $yiq-text-dark;
  }
}
