.imagecheck {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.imagecheck-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.imagecheck-figure {
  border: 1px solid $border-color;
  border-radius: 3px;
  margin: 0;
  position: relative;

  .imagecheck-input:focus ~ & {
    border-color: $primary;
    box-shadow: $input-btn-focus-box-shadow;
  }

  .imagecheck-input:checked ~ & {
    border-color: $border-color-dark;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    user-select: none;
    background: $primary $custom-checkbox-indicator-icon-checked no-repeat
      center center/50% 50%;
    color: #fff;
    z-index: 1;
    border-radius: 3px;
    opacity: 0;
    transition: 0.3s opacity;

    .imagecheck-input:checked ~ & {
      opacity: 1;
    }
  }
}

.imagecheck-image {
  max-width: 100%;
  opacity: 0.64;
  transition: 0.3s opacity;

  &:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  .imagecheck:hover &,
  .imagecheck-input:focus ~ .imagecheck-figure &,
  .imagecheck-input:checked ~ .imagecheck-figure & {
    opacity: 1;
  }
}

.imagecheck-caption {
  text-align: center;
  padding: 0.25rem 0.25rem;
  color: $text-muted;
  font-size: $font-size-sm;
  transition: 0.3s color;

  .imagecheck:hover &,
  .imagecheck-input:focus ~ .imagecheck-figure &,
  .imagecheck-input:checked ~ .imagecheck-figure & {
    color: $body-color;
  }
}
