/**
Dashboard UI
 */

@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "dashboard/dashboard";
@import "autocomplete";
@import "growl";

.custom-select-sm {
  padding-top: 0;
}

.form-inline label.custom-file-label {
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-header-tabs {
  line-height: inherit;
}

.btn-save .fe {
  animation: pulse 2s infinite;
}

.header {
  display: flex;
  border-bottom: none;
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-brand,
.header-text,
.header-contact {
  flex: 1 0 0;
}

.header-brand {
  margin-right: 0;
}

.header-brand-img {
  height: 10rem;
}

.header-text {
  font-size: $h3-font-size;
  font-weight: $font-weight-normal;
  text-align: center;
}

.header-contact {
  font-size: $font-size-lg;
}

.header-contact a {
  color: $white;
}

.header-contact span {
  display: block;
  text-align: right;
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.page-subtitle {
  display: block;
}

.product-search {
  position: relative;
}

.search-results-dropdown {
  background: #fff;
  position: absolute;
  top: 38px;
  left: 0;
  width: 100%;
  z-index: 1000;
  max-height: 15rem;
  overflow: auto;
}

[data-controller="popover"] {
  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

.contact-block span {
  display: block;
}

.header {
  background: $brand;
  color: $white;

  .nav-tabs .nav-link {
    color: $white;

    &:hover {
      color: $white;
      border-bottom-color: $white;
    }
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: $white;
  }
}

.form-group.row {
  display: flex;
}

.selectgroup-item {
  margin: 0 0.5rem 0.5rem 0;
}

.selectgroup-item:not(:last-child) .selectgroup-button {
  border-radius: 3px;
}

.selectgroup-item:not(:first-child) .selectgroup-button {
  border-radius: 3px;
}

.viewer {
  max-width: 100%;
}

.account-name {
  font-weight: $headings-font-weight;
  font-size: $font-size-base;
}

.contact-card {
  font-size: $font-size-sm;

  .account-name,
  .co-name,
  .address {
    display: block;
  }

  .address {
    @extend .mb-3;
  }

  .contact-info .method {
    display: inline-block;
    width: 60px;

    text-transform: uppercase;
  }
}

.cell {
  display: flex;
  align-content: start;

  p {
    margin-bottom: 0;
  }
}

.cell-title {
  @extend .text-muted;
  text-transform: uppercase;
}

.cell-title,
.cell-body {
  flex-basis: 0;
  flex-grow: 1;
}

.loading {
  content: "";
  -webkit-animation: loader 500ms infinite linear;
  animation: loader 500ms infinite linear;
  border: 2px solid #fff;
  border-radius: 50%;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  display: block;
  height: 1.4em;
  width: 1.4em;
  left: calc(50% - (1.4em / 2));
  top: calc(50% - (1.4em / 2));
  -webkit-transform-origin: center;
  transform-origin: center;
  position: absolute !important;
}

.pdf-viewer {
  width: 100%;
  min-height: 640px;
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.auth-help-text a {
  text-decoration: underline;
}
